.map .white-box.guidance {
	position: absolute;
	bottom: 85%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999;
	padding: 10px 15px;
	background: rgba(255, 255, 255, 0.95);
	transition: visibility 0.3s ease, opacity 0.3s ease;
	display: flex;
	flex-direction: column;
	gap: 10px;
	pointer-events: none;
}

.guidance .p-divider.p-divider-horizontal {
	margin: unset;
	padding: unset;
}