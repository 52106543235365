.legend-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 10px;
}


.legend-container .single-legend {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*width: fit-content;*/
    min-width: 30%;
}

.legend-container .single-legend .labels{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 4px;
    gap: 8px;
}

.legend-container .single-legend .labels p {
    /*font-size: 0.7vw;*/
    font-size: 12px;
    margin: 0;
}

.legend-container .single-legend .legend {
    height: 20px;
    width: 100%;
}

.legend-group h3 {
    font-size: 16px;
}