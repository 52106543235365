#map-dialog {
	transform: unset !important;
	position: absolute;
	right: 0;
	bottom: 0;
	height: 30vh;
	width: 40vh;
	background-color: white;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px;
	gap: 10px;
	box-shadow: -6px 9px 11px -1px rgba(0,0,0,0.41);
	-webkit-box-shadow: -6px 9px 11px -1px rgba(0,0,0,0.41);
	-moz-box-shadow: -6px 9px 11px -1px rgba(0,0,0,0.41);
	transition: right .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
	border-radius: 4px;
	pointer-events: initial;
	min-height: 400px;
	min-width: 550px;
}

#map-dialog .header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

#map-dialog .header-text {
	font-size: 1.2em;
}

#map-dialog .header .dialog-close {
}

.p-dialog#map-dialog .p-dialog-header {
	padding: 1rem;
}

.p-dialog#map-dialog .p-dialog-title {
	gap: 12px;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.p-dialog#map-dialog .p-dialog-header-icons {
	position: absolute;
	right: 5%;
}

#map-dialog .header-text {
	text-align: center;
	font-size: 1.5em;
}

#map-dialog .subheader-text {
	font-size: 1.2em;
	text-align: center;
	font-weight: bold;
	padding-bottom: 12px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
