.p-grid {
  margin: 0;
}

.p-component, .p-link {
  font-family: Montserrat;
}

h1,h2,h3,h4,h5 {
  font-family: Lato;
  margin: 0;
}

p, span, div {
  font-family: Montserrat;
  margin: 0;
}

a {
  text-decoration: unset;
  overflow-wrap: break-word;
  max-width: 100%;
}

.app {
  display: flex;
  flex-direction: column;
}

.white-box {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  /*box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);*/
  -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  /*width: 70%;*/
  align-self: center;
}

.weather {
  width: 70%;
}

h1, h3 {
  color: var(--cc-dark-blue);
  text-align: center;
}

h3 {
  font-weight: normal;
}

:root {
  --cc-dark-blue: #1c3665;
  --cc-light-blue-transparent: rgb(0, 108, 229, 0.5);
  --cc-light-blue: #006ce5;
  --cc-text-grey: #495057;
  --cc-pure-white: white;
  --cc-bright-red: #f32212;
}