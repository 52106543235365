.menu-bar {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.menu-bar h1 {
	margin: 15px 0 15px 35px;
	color: var(--cc-dark-blue);
}

.menu-bar button {
	background: unset;
	border: unset;
}

.menu-bar .p-button {
	border: solid 1px var(--cc-dark-blue);
	margin-right: 35px;
}

.menu-bar .p-button:hover {
	color: var(--cc-pure-white) !important;
	background-color: var(--cc-dark-blue) !important;
}