.overlay {
	width: 100%;
	height: 100vh;
	background-color: rgba(211,211,211, 0.5);
	position: fixed;
	z-index: 9999;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.loader .p-progress-spinner-circle{
	stroke: #1245ee !important;
}