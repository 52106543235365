.search-box .search-button {
	min-width: 75px;
	position: relative;
	right: 75px;
}

.search-box .p-button-text {
	color: var(--cc-text-grey);
}

.search-box .p-button.p-button-text:enabled:hover {
	background: rgba(27, 135, 255, 0.1);
}
.search-box .p-button:focus {
	box-shadow: unset;
}

.search-box {
	display: flex;
	min-width: 550px;
	height: 50px;
}

.search-term {
	font-weight: bold;
}


.option-template {
	display: flex;
	flex-direction: row;
	gap: 15px;
	margin-right: 10px;
}

.option-template i {
	color: var(--cc-text-grey);
}


.search-field {
	height: 50px;
	min-width: 550px;
}

.p-autocomplete-input-token {
	width: 70px;
}

.value-template {
	display: flex;
	flex-direction: row;
	gap: 15px;
	margin-right: 10px;
}

.value-template p, i {
	color: var(--cc-pure-white);
}

.search-box .p-autocomplete-token-icon {
	color: var(--cc-pure-white);
}

.search-box .p-autocomplete-token {
	background: var(--cc-light-blue) !important;
}

.search-box .p-autocomplete-multiple-container {
	flex-wrap: nowrap;
	width: 100%;
}

.search-box .p-autocomplete-multiple-container:not(.p-disabled):hover {
	border-color: var(--cc-light-blue);
}

.search-box .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
	border-color: var(--cc-light-blue);
	/*box-shadow: 0 0 0 0.2rem #c8e2ff !important;*/
	box-shadow: unset;
}

.search-box .p-autocomplete-loader {
	display: none;
}