.large-map {
	height: 70vh;
	width: 100%;
}

.small-map {
	height: 400px;
	width: 400px;
}

.small-map-wide {
	height: 400px;
	width: 100%;
}

.leaflet-container {
	height: 100%;
	width: 100%;
}

.custom-div-icon-red i{
	margin: 12px auto;
	font-size: 35px;
	color: var(--cc-bright-red);
	-webkit-text-stroke: 1px white;
	/*text-shadow: 0 0 3px white;*/
}

.custom-div-icon-blue i{
	margin: 12px auto;
	font-size: 35px;
	color: var(--cc-light-blue);
	-webkit-text-stroke: 1px white;
	/*text-shadow: 0 0 3px white;*/
}

.button-container .leaflet-buttons-control-button {
	display: flex;
	align-items: center;
	justify-content: center;
}

.button-container .leaflet-buttons-control-button .control-icon {
	width: unset;
	height: unset;
}