.map-loader.overlay {
	width: 100%;
	height: 100%;
	background-color: rgba(211,211,211, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity 0.2s, height 0.1s 0.2s;
}

.loader .p-progress-spinner-circle{
	stroke: #1245ee !important;
}

.map-loader h3 {
	/*font-weight: bold;*/
	font-size: 1.5em;
	padding: 8px 16px;
}