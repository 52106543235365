.layer-picker {
	position: absolute;
	z-index: 999;
	right: 0;
	margin: 15px;
	padding: 5px;
	border-radius: 4px;
	border: 2px solid rgba(0,0,0,0.3);
	box-shadow: unset;
}

.picker-option {
	padding: 2px;
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
}

.picker-option .p-radiobutton {
	width: 15px;
	height: 15px;
	display: flex !important;
	align-content: center;
}

.picker-option .p-radiobutton-box {
	width: 15px;
	height: 15px;
}

.picker-option label {
	font-size: 0.9em;
}