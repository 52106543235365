.var-picker .p-selectbutton .p-button {
	justify-content: center;
}

.select-item-icon {
	color: var(--cc-dark-blue);
	font-size: 1.3em;
}

.var-picker {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	justify-content: center;
}

.var-picker h3 {
	font-size: 1.2em !important;
}