.map-div {
	position: relative;
	overflow: hidden;
}

.map {
	position: relative;
}

.small-map-container .layer-picker {
	display: none;
}

.small-map-container .leaflet-pm-toolbar {
	display: none;
}

.small-map-wide-container .layer-picker {
	display: none;
}

.small-map-wide-container .leaflet-pm-toolbar {
	display: none;
}


/*.small-map-container .legend-container {*/
/*	display: none;*/
/*}*/